import React from 'react';
// import { graphql } from 'gatsby';

// import PageHero from 'src/components/content/PageHero';
import PageHeader from 'src/components/content/PageHeader';
import MarkdownSectionsNavigator from 'src/components/markdown/MarkdownSectionsNavigator';
import { useLocalization } from 'src/providers/LocalizationProvider';
import DataProvider from 'src/providers/DataProvider';
import Box from '@material-ui/core/Box';

export default function ResourcesPage({ data }) {
  // const {
  //   allFile: { nodes: heroImages },
  // } = data;

  const { page } = useLocalization();
  // <PageHero
  //   focusColor="brand.membership"
  //   textColor="common.white"
  //   text={t('hero.membership')}
  // />

  return (
    <DataProvider>
      <Box pb={10}>
        <PageHeader />
      </Box>
      <MarkdownSectionsNavigator page={page} focusColor="brand.membership" />
    </DataProvider>
  );
}

// export const query = graphql`
//   query ResourcesPage {
//     allFile(
//       limit: 2
//       filter: { relativePath: { regex: "/hero/resources(.*)/i" } }
//     ) {
//       nodes {
//         childImageSharp {
//           fluid(maxHeight: 280, maxWidth: 380) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   }
// `;
